import axios from "axios"
import { isBrowser } from "./util"

export const sendContact = (values) => {
    return new Promise(function(resolve, reject) {
        if(!isBrowser()){
            reject("Do not do this from Node!")
        }else{
            const contactSubmitUrl = process.env.GATSBY_CONTACT_API_URL
            values.env = process.env.GATSBY_DATOCMS_ENV;
            axios.post(
                `${contactSubmitUrl}`,
                values,
                {
                    headers: {
                        'X-Api-Key': 'siW3Dbkdv07NjsemeBypH1qC246iFTJ68iqBX6WG'
                    }
                }
            ).then((result) => {
                resolve(result)
            }).catch((err)=>{
                reject(err)
            })
        }
    })
}